import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Image from "../image/Image"
import "./PostEntry.scss"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";

deckDeckGoHighlightElement();

const _MAX_EXCERPT_LENGTH = 275

const PostEntry = ({ title, href, image, excerpt, tags, os, dificultad }) => {
    if(excerpt && excerpt.length > _MAX_EXCERPT_LENGTH) {
        excerpt = `${excerpt.substring(0, _MAX_EXCERPT_LENGTH)}...`
    }

    let dificultadClassName = '';
    switch(dificultad) {
        case 'Fácil':
            dificultadClassName = 'post-entry_dificultad-easy';
            break;
        case 'Medio':
            dificultadClassName = 'post-entry_dificultad-medium';
            break;
        case 'Difícil':
            dificultadClassName = 'post-entry_dificultad-hard';
            break;
        default:
            dificultadClassName = '';
            break;
    }

    return(
        <div className="post-entry">
            <div className="post-entry_image-container">
                <Link to={ href } title={ title }>
                    <Image
                        width={ 195 }
                        height={ 195 }
                        alt={ title }
                        title={ title }
                        className="post-entry_image"
                        fileName={ image || 'posts/default-post-entry.png' }
                    />
                </Link>
            </div>
            <div className="post-entry_body">
                <Link to={ href } title={ title }>
                    <h2 className="post-entry_title">{ title }</h2>
                </Link>
                <span className={`post-entry_dificultad ${dificultadClassName}`}>{ dificultad }</span>
                <span className="post-entry_os">{ os }</span>
                <p className="post-entry_excerpt hidden-with-shadow">{ excerpt }</p>
                <p className="post-entry_read-more">
                    <Link to={ href } title={ `Leer más sobre ${title}` }>Leer más</Link>
                </p>
            </div>
        </div>
    )
}

PostEntry.propTypes = {
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    tags: PropTypes.array,
    featuredImage: PropTypes.object,
    os: PropTypes.string,
    dificultad: PropTypes.string
}

export default PostEntry
